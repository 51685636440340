import React from "react"
//import Img from "gatsby-image"

/* eslint-disable */
import loader from "../images/weather/loader.svg"
import sn from "../images/weather/sn.svg"
import sl from "../images/weather/sl.svg"
import h from "../images/weather/h.svg"
import t from "../images/weather/t.svg"
import hr from "../images/weather/hr.svg"
import lr from "../images/weather/lr.svg"
import s from "../images/weather/s.svg"
import lc from "../images/weather/lc.svg"
import hc from "../images/weather/hc.svg"
import c from "../images/weather/c.svg"
import eye from "../images/weather/eye.svg"

const images = {
    sn: sn,
    sl: sl,
    h: h,
    t: t,
    hr: hr,
    lr: lr,
    s: s,
    hc: hc, 
    lc: lc, 
    c: c,
    eye: eye
}


class WeatherIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loader: true,
          temp: null,
          count: 0
        }
    }
    componentDidMount(){
        this.setState({
            loader: false
        })
    }    
    componentDidUpdate(){
        /*if (this.props.temp) {
            if (this.state.count < 20){
                this.setState({
                    temp: tempUp,
                    loader: false,
                    count: this.state.count + 1
                })
            }    
        }*/
        if(this.props.temp) {
            let tempUp = this.props.temp.toFixed(1) + "°C"
            if (tempUp !== this.state.temp){
                this.setState({
                    temp: tempUp,
                })
            }
        }
    }
    render(){
        const { props } = this.props
        return (
            <div className="race--forecast">
                <img 
                    src={`${this.props.icon ? images[this.props.icon] : loader}`} 
                    className={this.props.icon ? "" : "loader"}
                    alt={this.props.icon ? this.props.icon : "weather icon"} 
                />
                <p>{this.state.temp}</p>
            </div>
     )
    }
}
export default WeatherIcon
