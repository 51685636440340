// eslint-disable-next-line
import { fetch as fetchPolyfill } from 'whatwg-fetch'
import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import WeatherIcon from "../components/weatherIcon"
import Img from "gatsby-image"
import Countdown from 'react-countdown-now'
import * as $ from 'jquery'
import "../styles/races.css"
import Helmet from "react-helmet"
import {monthNames, dayNames, pad, replaceWords} from '../components/names'
import { rendererFull } from '../components/renderers';

class Race extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      lat: 0.0,
      long: 0.0,
      time: null,
      forecast: null,
      forecastIcon: null,
      stats: []
    }
  }


  componentDidMount() {  
    
    const { data } = this.props

    if (data.directusRace.position !== null && data.directusRace.position > 0) {
      this.setState( position => {
        const stateQ = position.stats.push({"name":"Position","value": nth(data.directusRace.position)})
        return {  
          stateQ
        }
      })  
    }
    if (data.directusRace.fastest_laptime !== null && data.directusRace.fastest_laptime > 0) {
      let seconds = Number(Math.round(data.directusRace.fastest_laptime % 60+'e2')+'e-2');
      let minutes = Math.floor(data.directusRace.fastest_laptime / 60)
      this.setState( fastest_laptime => {
        const stateQ = fastest_laptime.stats.push({"name":"Hurtigste omgangstid","value": minutes + ":" + seconds})
        return {  
          stateQ
        }
      })  
    }
    if (data.directusRace.speed !== null && data.directusRace.speed > 0) {
      this.setState( state => {
        const stateQ = state.stats.push({"name":"Gns. hastighed (km/t)","value": data.directusRace.speed})
        return {  
          stateQ
        }
      })  
    }
    if (data.directusRace.laps !== null && data.directusRace.laps > 0) {
      this.setState( laps => {
        const stateQ = laps.stats.push({"name":"Omgange","value": data.directusRace.laps})
        return {  
          stateQ
        }
      })  
    }


    // Get lan and long of the place
    const placeName = document.getElementsByClassName("race--circuit-name")[0].textContent
    window.fetch('https://eu1.locationiq.com/v1/search.php?key=32336021afc343&q=' + placeName + '&format=json')
    .then(results => {
      return results.json();
    }).then(data => {
      data.map((info) => {
        return (
          this.setState({
            lat: info.lat,
            long: info.lon
          })
        )
      })
    })
    
    // Get UNIX timestamp of the starting date
    const startTime = document.getElementsByClassName("race--date")[0].textContent.split(" ")
    let startTimeFinal = ""
    startTime.forEach(function(currentValue, index, arr) {
      let firstTime = currentValue.split("-")
      startTimeFinal += firstTime[0] + " "
      }
    )
    
    var newdate = new Date(startTimeFinal);
    newdate.setDate(newdate.getDate() + 3);

    this.setState({
      time: new Date(startTimeFinal).getTime()
    })

    function nth(d) {
      if(d>3 && d<21) return d+' th'; // thanks kennebec
      switch (d % 10) {
            case 1:  return d+" st";
            case 2:  return d+" nd";
            case 3:  return d+" rd";
            default: return d+" th";
        }
    } 
  }

componentDidUpdate() {       
      if (this.state.forecast === null) {
        let fromDateUrl = new Date(this.state.time).getFullYear() + "/" + (new Date(this.state.time).getMonth() + 1) + "/" + new Date(this.state.time).getDate()

          window.fetch('https://cors-anywhere.herokuapp.com/https://www.metaweather.com/api/location/search/?lattlong=' + this.state.lat + ',' + this.state.long)
          .then(response => response.json())
          .then(place => {
            fetch('https://cors-anywhere.herokuapp.com/https://www.metaweather.com/api/location/' + place[0].woeid + "/" + fromDateUrl)
            .then(response => response.json())
            .then(weather => {
              if (weather.length > 0){
                weather.sort(function(a, b) {
                  return b.predictability - a.predictability;
                });
                weather.sort(function(a, b) {
                  return b.created - a.created;
                });
                this.setState({
                  forecast: weather[0].the_temp,
                  forecastIcon: weather[0].weather_state_abbr
                }) 
              } else {
                this.setState({
                  forecast: "",
                  forecastIcon: "eye"
                })
              }
            })
          })
      }
}

render() {
  const { data } = this.props


function getTimezoneOffset(d, tz) {
  var a = d.toLocaleString("ja", {timeZone: tz}).split(/[/\s:]/);
  a[1]--;
  var t1 = Date.UTC.apply(null, a);
  var t2 = new Date(d).setMilliseconds(0);
  return (t2 - t1) / 60 / 1000;
}
if (typeof document !== 'undefined') {

$( document ).ready(function() {
  $('.open-iframe').click(function(){
    var frameName = $(this).attr('frame');
    $(frameName).addClass("active");
    $('body').addClass("noscroll")
  })

  $('.open-map').click(function(){
    var frameName = $(this).attr('frame');
    $(frameName).addClass("active");
    $(".race--hotspots-frame").addClass("active");
    $(".race--circuit-map").addClass("blurred")
})

$('.race--hotspots-frame section .close').click(function(){
    $(".race--hotspots-frame section").removeClass("active");
    $(".race--hotspots-frame").removeClass("active");
    $(".race--circuit-map").removeClass("blurred")
})

$('.fullscreen--video-player').click(function(){
    $('.fullscreen--video-player').removeClass("active");
    $('body').removeClass("noscroll");
    var src= $('.fullscreen--video-player iframe').attr('src');
    $('.fullscreen--video-player iframe').attr('src',src);  
})

var previousClass;
$("[class*=dateOf]").each(function(index) {
  // loop trough all elements in the container and get the class of the current element
  var currentClass = $(this).attr('class');

  // compare the elements class with the previous one. 
  // if it matches, hide it
  if (currentClass === previousClass) {
    $(this).addClass("hide");
  }

  // before we go to the next element, update the previousClass 
  // so we can compare it in the next iteration
  previousClass = currentClass;
});


})
}

  return (
    <>
      <SEO title={`${data.directusRace.race_title}`} />
      <Helmet>
        <meta property="og:image" content={data.directusRace.image.localFile.url} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.mbracing.dk/race/${data.directusRace.race_url}`} />
        <meta property="og:image:alt" content={`Cover image for ${data.directusRace.race_title}`} />
      </Helmet>
        <nav className="nav--top-back">
          <Link to="/races">Tilbage til løbene</Link>
        </nav>
        <article className="race">
          <section className="race--entry-top">
            <Img fluid={data.directusRace.image.localFile.childImageSharp.fluid} className="race--top-image opaque80"/>
            <div className="race--entry-heading">
              <h1 className="thin">{data.directusRace.type.type_name}</h1>
              <h1>{data.directusRace.race_title}</h1>
            </div>
          </section>
          <section className="race--countdown">
            <Countdown date={data.directusRace.from.replace(/-/g, '/')} renderer={rendererFull} className="races--count"/> 
          </section>
          <section className="race--date">
            {new Date(data.directusRace.from.replace(/-/g, '/')).getMonth() === new Date(data.directusRace.to.replace(/-/g, '/')).getMonth() ? 
              new Date(data.directusRace.from.replace(/-/g, '/')).getDate() + "-" + new Date(data.directusRace.to.replace(/-/g, '/')).getDate() + " " + monthNames[new Date(data.directusRace.to.replace(/-/g, '/')).getMonth()] + " " + new Date(data.directusRace.to.replace(/-/g, '/')).getFullYear()
            : 
              new Date(data.directusRace.from.replace(/-/g, '/')).getDate() + " " + monthNames[new Date(data.directusRace.from.replace(/-/g, '/')).getMonth()] + "-" + new Date(data.directusRace.to.replace(/-/g, '/')).getDate() + " " + monthNames[new Date(data.directusRace.to.replace(/-/g, '/')).getMonth()]  + " " + new Date(data.directusRace.to.replace(/-/g, '/')).getFullYear()
            }
            <WeatherIcon icon={this.state.forecastIcon} temp={this.state.forecast}/>
          </section>
          <section className="race--circuit-text">
            <h2 className="race--circuit-name opaque80">{data.directusRace.circuit.name}</h2>
            <div dangerouslySetInnerHTML={{__html:data.directusRace.circuit.descr}} className="race--circuit-descr opaque80"></div>

            {data.directusRace.text && <>
              <br />
              <h3 className="race--circuit-descr opaque80">Om løbet</h3>
              <div dangerouslySetInnerHTML={{__html:data.directusRace.text}} className="race--circuit-descr opaque80"></div>
            </>}

            {data.directusRace.car_types && <>
              <br />
              <h3 className="race--circuit-descr opaque80">Køret med</h3>
              <p className="race--circuit-descr">{data.directusRace.car_types.map(c => c.car_type).join(", ")} biler</p>
            </>}

            {data.directusRace.livestreaming && (
              <div>
                <h4>Livestreaming</h4>
                <p className="race--stream-link"><a href={data.directusRace.livestreaming} target="_blank" rel="noopener noreferrer">{data.directusRace.livestreaming}</a></p>
              </div>
            )}
            {data.directusRace.livetiming && (
              <div>
                <h4>Livetiming</h4>
                <p className="race--stream-link"><a href={data.directusRace.livetiming} target="_blank" rel="noopener noreferrer">{data.directusRace.livetiming}</a></p>
              </div>
            )}
          </section>
          { this.state.stats.length > 0 && (
            <section className="race--stats">
              <h2 className="heading">Statistik</h2>
              <div>
                { this.state.stats.map(stat => {
                    return(
                      <article key={stat.value}>
                        <p>{stat.name}</p>
                        <span>{stat.value}</span>
                      </article>
                    )
                  })
                }
              </div>
            </section>
          ) }
          <section className="race--circuit">
          <div className="race--circuit-info">
            <h2 className="opaque30">Banen</h2>
              {data.directusRace.circuit.video && <span frame="#video-of-circuit" className="race--circuit-video open-iframe click">Video af banen</span> }
            <dl>
              {typeof data.directusRace.circuit.direction !== 'undefined' && (
                <div>
                  <dt>retning</dt>
                  <dd style={{textTransform: 'capitalize'}}>{replaceWords(data.directusRace.circuit.direction)}</dd> 
                </div>
              )} 
              {typeof data.directusRace.circuit.length !== 'undefined' && (
                <div>
                  <dt>længde</dt>
                  <dd>{data.directusRace.circuit.length} m</dd>
                </div>
              )} 
              {typeof data.directusRace.circuit.turns !== 'undefined' && (
                <div>
                  <dt>sving</dt>
                  <dd>{data.directusRace.circuit.turns}</dd>
                </div>
              )} 
            </dl>
            </div>
            <div className="race--circuit-experience">
              <div className="race--circuit-map">
                <Img fluid={data.directusRace.circuit.circuit_map.localFile.childImageSharp.fluid} className="race--map-image" />
                <section className="race--circuit-points">
                  {data.directusRace.circuit.turns_collection.map(edge => (
                    <span key={edge.id} frame={`#${edge.id}`} style={{left: `${edge.position_x}%`, top: `${edge.position_y}%` }} className="open-map click" data-name={edge.name}></span>
                  ))}
                </section> 
              </div>
              <div className="race--hotspots-frame">
                {data.directusRace.circuit.turns_collection.map(edge => (
                  <section key={edge.id} id={edge.id}>
                    <span className="close click" style={{position: 'absolute', alignSelf: 'flex-end'}}></span>
                    <h4><span className="race--hotspot-bold">Sving </span>{edge.name}</h4>
                    <p>{edge.description}</p>
                  </section>
                ))}

              </div>
            </div>
          </section>
          <section className="race--schedule">
            <h2 className="opaque30">Tidsplan</h2>
            {data.allDirectusTimeSchedule.edges.length <= 0 && (
              <p>Tidsplanen er ikke klar endnu.</p>
            )}
            {data.allDirectusTimeSchedule.edges.map(edge => (
              <section key={edge.node.id} id={edge.node.id} className={`race--schedule-entry dateOf${new Date(edge.node.end.replace(/-/g, '/')).getDate() + "-" + new Date(edge.node.end.replace(/-/g, '/')).getMonth()}`}>
                <h3 className="race--schedule-date">{dayNames[new Date(edge.node.end.replace(/-/g, '/')).getDay()]}, {new Date(edge.node.end.replace(/-/g, '/')).getDate()} {monthNames[new Date(edge.node.end.replace(/-/g, '/')).getMonth()]}</h3>
                <p className="race--schedule-time">
                  {pad(new Date(edge.node.start.replace(/-/g, '/')).getHours()) + ":" + pad(new Date(edge.node.start.replace(/-/g, '/')).getMinutes()) } <span>{ 
                     pad(new Date(new Date(edge.node.start.replace(/-/g, '/')).setMinutes(new Date(edge.node.start.replace(/-/g, '/')).getMinutes() + (- (getTimezoneOffset(new Date()) - getTimezoneOffset(new Date(), data.directusRace.circuit.timezone))))).getHours()) + ":" + pad(new Date(new Date(edge.node.start.replace(/-/g, '/')).setMinutes(new Date(edge.node.start.replace(/-/g, '/')).getMinutes() + (- (getTimezoneOffset(new Date()) - getTimezoneOffset(new Date(), data.directusRace.circuit.timezone))))).getMinutes())
                  } DK</span>
                </p>
                <h4>{edge.node.name} ({
                    new Date(new Date(edge.node.end.replace(/-/g, '/')).getTime() - new Date(edge.node.start.replace(/-/g, '/')).getTime() ) / 1000 / 60
                  } min)
                </h4>

              </section>
            ))}
          </section>

        </article>
        {data.directusRace.circuit.video && <div className="fullscreen--video-player" id="video-of-circuit">
          <iframe frameBorder="0" src={`//www.youtube.com/embed/${data.directusRace.circuit.video.embed}`} allowFullScreen={true} title={data.directusRace.circuit.name}></iframe>
        </div>}
    </>
  )}
}
export default Race

export const query = graphql`
  query($id: String!) {
    directusRace(id: { eq: $id}) {
      id
      from
      speed
      position
      text
      car_types {
        car_type
      }
      laps
      fastest_laptime
      livestreaming
      livetiming
      type {
        type_name
      }
      circuit {
        name
        country
        descr
        length
        turns
        turns_collection {
          id
          description
          position_x
          position_y
          name
        }
        direction
        video {
          embed
        }  
        timezone
        circuit_map {
          localFile {
            childImageSharp {
              fluid(quality: 40) {
                ...GatsbyImageSharpFluid_withWebp                
              }
            }
          }
        }
      }
      image {
        localFile {
          childImageSharp {
            fluid(quality: 85) {
              ...GatsbyImageSharpFluid_withWebp                
            }
          }
          url    
        }
      }
      to
      race_url
      race_title
    }
    allDirectusTimeSchedule(filter: {status: {eq: "published"}, race: {id: {eq: $id}}}, sort: {fields: start, order: ASC}) {
      edges {
        node {
          id
          name
          start
          end
        }
      }
    }
  }

`